import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { actions as notification } from '@skunexus/notification';
import { ajaxExceptionMessage } from '@skunexus/utils';
import { Spinner } from '@skunexus/component-catalog';

import { useConfigContext } from '../../../../../contexts/ConfigContext';
import * as API from '../api';

export const withAuthorizationHoc = () => (Component) => {
    function WrappedComponent(props) {
        const { params, history, routes: { pickPageRoute }, errorNotification = () => {} } = props;

        const [{ setting }] = useConfigContext();
        const { isKittingEnabled, isForceEntireKittingEnabled } = setting;

        const [isAuthorizing, setAuthorizing] = useState(true);

        const checkAuthorization = async ({ fulfillmentId = '' } = {}) => {
            setAuthorizing(true);
            try {
                await API.authorizePick({ fulfillmentId });
                setAuthorizing(false);
            } catch (e) {
                errorNotification(ajaxExceptionMessage(e));

                setTimeout(() => {
                    history.push(pickPageRoute);
                }, 1000);
            }
        };

        useEffect(() => {
            const { id } = params;
            if (id) {
                checkAuthorization({ fulfillmentId: id });
            }
        }, [params?.id]);

        if (isAuthorizing) {
            return <Spinner className="mt-3" />;
        }

        return <Component {...props} {...{ isKittingEnabled, isForceEntireKittingEnabled }}/>;
    }

    const mapDispatchToProps = {
        errorNotification: notification.error,
        successNotification: notification.success,
    };

    return connect(null, mapDispatchToProps)(WrappedComponent);
};
