export default {
    name: 'justAddingPage',
    before: [],
    after: [],
    setup: (app) => {
        const pageName = 'receiveInventoryJai';
        const pageRoute = app.routes.getItem(pageName);
        if (pageRoute) {
            const routeProps = pageRoute.props;
            pageRoute.props = () => ({
                ...routeProps(),
                mode: {
                    withLocations: true,
                },
            });

            app.routes.changeItem(pageName, pageRoute);
        }
    },
};
