export default {
    name: 'receiveInventoryPage',
    before: [],
    after: [],
    setup: (app) => {
        const pageName = 'receiveInventoryMain';
        const pageRoute = app.routes.getItem(pageName);

        if (pageRoute) {
            const pageRouteProps = pageRoute.props;
          
            pageRoute.props = (state) => ({
                ...pageRouteProps(state),
                scanQuantityConfirmation: true
            });

            app.routes.changeItem(pageName, pageRoute);
        }
    },
};