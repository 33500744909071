import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Container, Row, Col, Alert } from 'reactstrap';
import { actions as notification } from '@skunexus/notification';
import { ajaxExceptionMessage } from '@skunexus/utils';
import { Spinner } from '@skunexus/component-catalog';

import * as API from '../api';

export const withAuthorizationHoc = () => (Component) => {
    function WrappedComponent(props) {
        const { params, history, routes: { groupPickPageRoute }, location, errorNotification = () => {} } = props;
        const [isAuthorizing, setAuthorizing] = useState(true);

        const checkAuthorization = async ({ fulfillmentGroupId = '' } = {}) => {
            setAuthorizing(true);
            try {
                await API.authorizeGroupPick({ fulfillmentGroupId });
                setAuthorizing(false);
            } catch (e) {
                const errorMsg = ajaxExceptionMessage(e);

                if (errorMsg === 'None has no value.') {
                    history.push(groupPickPageRoute);
                } else {
                    errorNotification(errorMsg);
                    setAuthorizing(false);

                    setTimeout(() => {
                        history.push({
                            pathname: window.location.pathname,
                            state: { isInvalid: true },
                        });
                        history.go(0);
                    }, 1500);
                }
            }
        };

        useEffect(() => {
            const { id } = params;
            if (id) {
                checkAuthorization({ fulfillmentGroupId: id });
            }
        }, [params?.id]);

        if (isAuthorizing) {
            return <Spinner className="mt-3" />;
        }

        return (
            <>
                {get(location, 'state.isInvalid', false) && (
                    <Container fluid>
                        <Row className="mt-3">
                            <Col>
                                <Alert color="warning" className="mb-0">
                                    Orders that failed authorization have been removed from the group. Continue picking
                                    remaining items.
                                </Alert>
                            </Col>
                        </Row>
                    </Container>
                )}
                <Component {...props} />;
            </>
        );
    }

    const mapDispatchToProps = {
        errorNotification: notification.error,
        successNotification: notification.success,
    };

    return connect(null, mapDispatchToProps)(WrappedComponent);
};
