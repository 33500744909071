import { withAuthorizationHoc } from './HOCs/PackingAuthorizationHoc';
import { addNotesToAttributesListInDetails } from '../../common/utils';

import {
    SHIP_PACK_ROUTE,
    SHIP_DETAILS_ROUTE,
    SHIP_PICKING_ROUTE,
    SHIP_PACKING_ROUTE,
} from '../../../../modules/fulfillment/consts';

import { ORDERS_DETAILS_ROUTE } from '../../../../modules/orders/consts';
import { PRODUCTS_DETAILS_ROUTE } from '../../../../modules/products/consts';
import { SHIPMENT_DETAILS_ROUTE, SHIPMENT_PRINT_ROUTE } from '../../../../modules/shipment/consts';

export default {
    name: 'fulfillmentShipPackingPage',
    before: [],
    after: [],
    setup: (app) => {
        const pageName = 'fulfillmentShipPacking';
        const pageRoute = app.routes.getItem(pageName);
        if (pageRoute) {
            const routeProps = pageRoute.props;
            pageRoute.props = () => ({
                ...routeProps(),
                infoProps: {
                    customRightAttributesList: addNotesToAttributesListInDetails,
                },
            });

            pageRoute.render = withAuthorizationHoc();
            app.routes.changeItem(pageName, pageRoute);
        }
    },
};
