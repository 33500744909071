import fulfillmentPickupGroupPickingPage from './fulfillment/pickup/fulfillmentPickupGroupPickingPage';
import fulfillmentPickupDetailsPage from './fulfillment/pickup/fulfillmentPickupDetailsPage';
import fulfillmentPickupPackingPage from './fulfillment/pickup/fulfillmentPickupPackingPage';
import fulfillmentPickupPickingPage from './fulfillment/pickup/fulfillmentPickupPickingPage';

import fulfillmentAllShipmentsGridPage from './fulfillment/ship/fulfillmentAllShipmentsGridPage';
import fulfillmentShipGroupPickingPage from './fulfillment/ship/fulfillmentShipGroupPickingPage';
import fulfillmentShipDetailsPage from './fulfillment/ship/fulfillmentShipDetailsPage';
import fulfillmentShipPackingPage from './fulfillment/ship/fulfillmentShipPackingPage';
import fulfillmentShipPickingPage from './fulfillment/ship/fulfillmentShipPickingPage';

import orderDetailsPage from './order/orderDetailsPage';

import purchaseOrderPrintPage from './purchaseOrder/purchaseOrderPrintPage';

import itemsReceivedPage from './receiveInventory/itemsReceivedPage';
import justAddingPage from './receiveInventory/justAddingPage';
import putAwayPage from './receiveInventory/putAwayPage';
import receiveInventoryPage from './receiveInventory/receiveInventoryPage';

export default {
    fulfillmentAllShipmentsGridPage,
    fulfillmentPickupGroupPickingPage,
    fulfillmentPickupDetailsPage,
    fulfillmentPickupPackingPage,
    fulfillmentPickupPickingPage,
    fulfillmentShipGroupPickingPage,
    fulfillmentShipDetailsPage,
    fulfillmentShipPackingPage,
    fulfillmentShipPickingPage,
    itemsReceivedPage,
    justAddingPage,
    putAwayPage,
    receiveInventoryPage,
    orderDetailsPage,
    purchaseOrderPrintPage,
};
